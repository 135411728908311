import { NavLink } from "react-router-dom";
import { useGlobalContext } from "../contexts/GlobalContext";
import { useAuth } from "../hooks/auth";

const Menu = ({ ulClass = "", liClass = "", anchorClass = "" }) => {
	const { user } = useAuth({ middleware: "guest" });
	const { hasPermission } = useGlobalContext();

	return (
		<ul className={ulClass}>
			{[
				{ title: "Records", url: "/records" },
				{ title: "Purchases", url: "/purchases" },
				{ title: "Status", url: "/status" },
				{ title: "SMS Templates", url: "/sms-templates" },
				{ title: "Users", url: "/users" },
			].map((item) => (
				<li
					key={item.title}
					className={
						liClass +
						(item.title === "Users" && hasPermission(["staff", "manager"], user)
							? " hidden "
							: "  ")
					}>
					<NavLink
						to={item.url}
						className={({ isActive }) =>
							anchorClass +
							(isActive
								? " text-green-500 border-green-500/100 font-semibold "
								: "")
						}>
						{item.title}
					</NavLink>
				</li>
			))}
		</ul>
	);
};

export default Menu;
