import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apiGet } from "../../inc/ApiHelpers";

const Jobsheet = () => {
	const [userData, setUserData] = useState();
	const [data, setData] = useState();
	let { recordId } = useParams();

	useEffect(() => {
		apiGet("/api/user?getalldata=1")
			.then((freshUserData) => {
				setUserData(freshUserData);
			})
			.then(() => {
				apiGet("/api/records/" + recordId).then((res) => setData(res));
			});
	}, []);

	useEffect(() => {
		if (data) {
			setTimeout(() => {
				window.print();
			}, 500);
		}
	}, [data]);
	return (
		<div
			className='text-[14px] max-w-[800px] mx-auto'
			styles={{ fontFamily: "arial" }}>
			<div className='container'>
				<div className='text-center leading-[1.4] flex justify-center'>
					{userData?.organization?.logo && (
						<img
							src={
								process.env.REACT_APP_API_URL +
								"/images/" +
								userData?.organization?.logo
							}
							className='mr-6 h-[70px] mt-1'
						/>
					)}

					<div>
						<div className='text-[30px] font-bold'>
							{userData?.organization?.name}
						</div>
						<div className='capitalize'>{userData?.organization?.address}</div>
						<div>Ph. {userData?.organization?.phone}</div>
					</div>
				</div>

				<div className='mt-2 flex justify-between'>
					<div>
						Job No.:{userData?.organization?.settings?.id_prefix}
						{data?.id}
					</div>

					<div>Date: {data?.acceptance_date}</div>
				</div>

				<div id='informations' className='leading-[1.6] mt-2'>
					<div>
						<div className='text-[16px] font-bold mb-[5px] border-b border-b-[rgb(220, 220, 220)]'>
							Customer Information
						</div>

						<div className='flex'>
							<div className='w-[50%] capitalize'>Name: {data?.name}</div>
							<div className='w-[50%]'>Phone No: {data?.phone}</div>
						</div>
						<div>Address: {data?.address}</div>
					</div>

					<div>
						<div className='text-[16px] leading-[1.6] font-bold my-[20px] mb-[5px] border-b border-b-rgb(220, 220, 220)'>
							Handset Information
						</div>

						<div className='flex justify-between'>
							<div className='w-[22%] capitalize'>Brand: {data?.brand}</div>
							<div className='w-[22%]'>Model: {data?.model}</div>
							<div className='w-[30%]'>IMEI: {data?.imei}</div>
							<div className='w-[26%]'>
								Appr. Cost:.{" "}
								{data?.approximate_cost ? "Nrs." + data?.approximate_cost : ""}
							</div>
						</div>
						<div>Problems: {data?.problems}</div>
						<div>Received Item - extra:</div>
					</div>
				</div>

				<div
					id='login-information'
					className='line-height: 1.6; margin-top: 20px'>
					<div className='text-[16px] leading-[1.6] font-bold my-[20px] mb-[5px] border-b border-b-rgb(220, 220, 220)'>
						Account Login
					</div>

					<div className='display: flex'>
						<div className='w-[33.33%]'>Gmail / iCloud / Other ID</div>
						<div className='w-[33.33%]'>User ID</div>
						<div className='w-[33.33%]'>Password</div>
					</div>
				</div>

				<div className='text-[16px] leading-[1.6] font-bold my-[20px] mb-[5px] border-b border-b-rgb(220, 220, 220)'>
					Phone Unlock
				</div>
				<div id='patterns-pin' className='flex justify-between'>
					<div className='w-[calc(50%-5px)]'>
						<div className='font-bold text-[14px] mb-[7px]'>Pattern</div>

						<div
							id='patterns'
							className='border border-[rgb(192, 192, 192)] h-[120px] py-3 px-[15%] '>
							<div className='float-left w-[33.33%]  h-[30px]  flex items-center justify-center '>
								1
							</div>
							<div className='float-left w-[33.33%]  h-[30px]  flex items-center justify-center '>
								2
							</div>
							<div className='float-left w-[33.33%] h-[30px] flex items-center justify-center '>
								3
							</div>
							<div className='float-left w-[33.33%]  h-[30px] flex items-center justify-center '>
								4
							</div>
							<div className='float-left w-[33.33%] h-[30px] flex items-center justify-center '>
								5
							</div>
							<div className='float-left w-[33.33%] h-[30px] flex items-center justify-center '>
								6
							</div>
							<div className='float-left w-[33.33%] h-[30px] flex items-center justify-center '>
								7
							</div>
							<div className='float-left w-[33.33%] h-[30px] flex items-center justify-center '>
								8
							</div>
							<div className='float-left w-[33.33%] h-[30px] flex items-center justify-center '>
								9
							</div>
						</div>
					</div>
					<div id='pin' className='w-[calc(50%-5px)]'>
						<div className='font-bold text-[14px] mb-[7px]'>Pin</div>
						<div className='border border-[rgb(192, 192, 192)] h-[120px]'></div>
					</div>
				</div>

				<div className='text-[16px] leading-[1.6] font-bold my-[20px] mb-[5px] border-b border-b-rgb(220, 220, 220)'>
					Terms &amp; Condition
				</div>
				<ul className='m-0 pl-[20px] leading-[1.4] text-[10px] list-disc'>
					{userData?.organization?.settings?.termsandconditions &&
						userData?.organization?.settings?.termsandconditions
							.split(/\r?\n/)
							.map((item, i) => <li key={i}>{item}</li>)}
				</ul>
				<div className='my-[10px] text-[12px] font-bold'>
					We have read and understood all the above terms and conditions and
					accept the same.
				</div>

				<div id='signatures' className='flex justify-between mt-[60px]'>
					<div>
						<div className='border-t text-sm border-t-black  pt-[5px] min-w-[200px] '>
							Signature of the customer
						</div>
					</div>

					<div>
						<div className='border-t text-sm border-t-black  pt-[5px] min-w-[200px] '>
							Received by
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Jobsheet;
