import TextareaAutosize from "react-textarea-autosize";
import ReactSelect from "../../components/forms/ReactSelect";

const Option = ({ selTab, setOptionsFormData, statuses, optionsFormData }) => {
	const getStatusNameById = (id) => {
		let title;
		statuses.forEach((item, i) => {
			if (item.id === id) {
				title = item.title;
				return;
			}
		});

		return title;
	};

	const getSelOptionsForStatus = () => {
		if (Array.isArray(optionsFormData?.default_status_in_records_page)) {
			return optionsFormData.default_status_in_records_page.map((item) => ({
				label: getStatusNameById(item),
				value: item,
			}));
		}
	};

	return (
		<>
			{selTab === "options" && (
				<>
					<h5 className='text-gray-600 font-light text-[30px] mb-2 '>
						Default Status
					</h5>

					<p className='text-gray-500 mb-6'>
						Default Status to show in Records page
					</p>

					<div className='flex items-center max-w-[600px]'>
						<ReactSelect
							selvalue={getSelOptionsForStatus()}
							options={statuses.map((item) => ({
								label: item.title,
								value: item.id,
							}))}
							id={"default_status_in_records_page"}
							multi={true}
							onChange={(key, val) => {
								setOptionsFormData((prev) => ({
									...prev,
									[key]: val,
								}));
							}}
						/>
					</div>

					<h5 className='text-gray-600 font-light text-[30px] mb-2 mt-14 '>
						Posts Per Page
					</h5>
					<p className='text-gray-500 mb-6'>
						Number of results to show in a single page.
					</p>

					<div className='flex items-center '>
						<input
							type='number'
							min='1'
							max='50'
							placeholder='15'
							id='posts_per_page'
							className='w-[100px] p-2 px-3 bg-white focus:outline-none border border-gray-200 rounded-sm text-gray-600 focus:border-blue-300 '
							value={optionsFormData?.posts_per_page}
							onChange={(e) => {
								setOptionsFormData((prev) => ({
									...prev,
									posts_per_page: e.target.value,
								}));
							}}
						/>
					</div>

					<div className='id-prefix'>
						<h5 className='text-gray-600 font-light text-[30px] mb-2 mt-14 '>
							ID Prefix
						</h5>

						<p className='text-gray-500 mb-6'>
							Text to show before the database ID in print.
						</p>

						<div className='flex items-center '>
							<input
								type='text'
								id='id-prefix'
								className='w-[300px] p-2 px-3 bg-white focus:outline-none border border-gray-200 rounded-sm text-gray-600 focus:border-blue-300 '
								value={
									optionsFormData?.id_prefix ? optionsFormData.id_prefix : ""
								}
								onChange={(e) => {
									setOptionsFormData((prev) => ({
										...prev,
										id_prefix: e.target.value,
									}));
								}}
							/>
						</div>
					</div>

					<div className='samaya-sms-api-key'>
						<h5 className='text-gray-600 font-light text-[30px]  mt-14 '>
							Samaya SMS
						</h5>

						{[
							{ id: "samayaSmsApiKey", label: "API Key" },
							{ id: "samayaSenderId", label: "Sender ID" },
							{ id: "samayaCampaignId", label: "Campaign ID" },
							{ id: "samayaRouteId", label: "Route ID" },
						].map((item) => (
							<div key={item.id}>
								<p className='text-gray-500 mb-2 mt-6'>{item.label}</p>

								<div className='flex items-center '>
									<input
										type='text'
										id={item.id}
										className='w-[300px] p-2 px-3 bg-white focus:outline-none border border-gray-200 rounded-sm text-gray-600 focus:border-blue-300 '
										value={
											optionsFormData?.[item.id] ? optionsFormData[item.id] : ""
										}
										onChange={(e) => {
											setOptionsFormData((prev) => ({
												...prev,
												[item.id]: e.target.value,
											}));
										}}
									/>
								</div>
							</div>
						))}
					</div>

					<h5 className='text-gray-600 font-light text-[30px] mb-2 mt-14 '>
						Terms &amp; Conditions
					</h5>

					<p className='text-gray-500 mb-6'>
						Your company's terms and conditions. New line will automatically
						convert to list items.
					</p>

					<TextareaAutosize
						onChange={(e) => {
							setOptionsFormData((prev) => ({
								...prev,
								termsandconditions: e.target.value,
							}));
						}}
						value={
							optionsFormData?.termsandconditions
								? optionsFormData?.termsandconditions
								: "Paste your terms and conditions here."
						}
						minRows={2}
						className='w-full text-xs p-2 px-3 bg-white focus:outline-none border border-gray-200 rounded-sm text-gray-600 focus:border-blue-300 '
					/>
				</>
			)}
		</>
	);
};

export default Option;
