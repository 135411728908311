import { useState } from "react";
import { useAuth } from "../hooks/auth";
import LoadingSvg from "../components/LoadingSvg";
import { Link } from "react-router-dom";

const ForgotPassword = () => {
	const { forgotPassword } = useAuth({ middleware: "guest" });

	const [email, setEmail] = useState("");
	const [errors, setErrors] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [emailSent, setEmailSent] = useState(false);

	const submitForm = async (event) => {
		event.preventDefault();

		setErrors("");

		setIsLoading(true);

		await forgotPassword({ email, setErrors, setEmailSent });

		setIsLoading(false);
	};

	return (
		<section className='flex justify-center items-center h-screen bg-gray-100 px-3'>
			{!emailSent && (
				<form
					onSubmit={submitForm}
					method='POST'
					className='max-w-md w-full bg-white rounded p-6 space-y-4'>
					<div className='mb-4'>
						<h2 className='text-xl font-bold'>Forgot Password ?</h2>
					</div>

					{errors && (
						<p className='alert bg-orange-200 border border-orange-300 px-4 py-3 rounded text-xs font-semibold'>
							{errors}
						</p>
					)}

					<div>
						<input
							className='w-full p-4 text-sm bg-gray-50 focus:outline-none border border-gray-200 rounded text-gray-600'
							type='email'
							placeholder='Email'
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</div>

					<div>
						{!isLoading && (
							<button
								type='submit'
								className='w-full py-4 bg-blue-600 hover:bg-blue-700 rounded text-sm font-bold text-gray-50 transition duration-200'>
								Submit
							</button>
						)}

						{isLoading && (
							<button
								type='button'
								className='w-full py-4 bg-blue-600 hover:bg-blue-700 rounded text-sm font-bold text-gray-50 transition duration-200 '>
								<LoadingSvg />
							</button>
						)}
					</div>
				</form>
			)}

			{emailSent && (
				<div className='max-w-md w-full bg-white rounded p-6 space-y-4'>
					<p>
						A link to reset your password has been sent to your email address.
						Please check your email.
					</p>

					<Link to='/login' className='text-blue-700 mt-3 inline-block'>
						Back to Login Page
					</Link>
				</div>
			)}
		</section>
	);
};

export default ForgotPassword;
