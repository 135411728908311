import { useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useTransition } from "@react-spring/web";
import { useAuth } from "../hooks/auth";
import UserDropDownMenu from "./headers/UserDropDownMenu";
import { useGlobalContext } from "../contexts/GlobalContext";
import { FaBars } from "react-icons/fa";
import Gravatar from "react-gravatar";
import Menu from "./Menu";

const Header = () => {
	let location = useLocation();

	const { user, logout } = useAuth({ middleware: "guest" });

	const [showUserMenu, setShowUserMenu] = useState(false);
	const [showMenu, setShowMenu] = useState(false);

	const userMenuRef = useRef(null);

	const displayName =
		user?.name !== undefined && user?.name !== null
			? user.name
			: "Hello Mobile Care";

	useEffect(() => {
		// hide user menu when clicked outside.
		function handleClickOutside(event) {
			if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
				setShowUserMenu(false);
			}
		}

		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [userMenuRef]);

	const transitions = useTransition(showUserMenu, {
		from: { opacity: 0, y: 24 },
		enter: { opacity: 1, y: 0 },
		leave: { opacity: 0, y: 24 },
	});

	if (location.pathname === "/") return <></>;

	return (
		<header className='header sticky top-0 bg-white shadow-md shadow-gray-200 flex items-center justify-between px-1 md:px-8 py-2 lg:py-0 print:hidden z-[9999]'>
			<button className='ml-2 lg:hidden' onClick={() => setShowMenu(!showMenu)}>
				<FaBars />
			</button>

			{showMenu && (
				<div className='lg:hidden fixed top-[40px] left-0 bottom-0 right-0'>
					<div className='w-full bg-white  shadow-lg p-2 '>
						<Menu liClass='py-2 px-1' anchorClass='block' />
					</div>
				</div>
			)}

			<h1 className=' font-bold text-md ml-3 lg:ml-0'>
				<img
					src={
						user?.organization?.logo
							? process.env.REACT_APP_API_URL +
							  "/images/" +
							  user.organization.logo
							: "/icon/default.png"
					}
					alt={user?.organization?.name}
					className=' h-[32px] lg:h-[40px]'
				/>
			</h1>

			<nav className='nav  text-md hidden lg:block'>
				<Menu
					ulClass='flex items-center'
					liClass='px-1 md:px-0 '
					anchorClass=' hover:text-green-500 hover:border-green-500 p-4 px-1 md:px-4 border-b-2 border-green-500/0  duration-200 cursor-pointer block '
				/>
			</nav>

			<div className=' text-sm relative flex justify-end '>
				<div ref={userMenuRef}>
					{user && (
						<button
							className='flex justify-end items-center mr-1'
							onClick={() => setShowUserMenu(!showUserMenu)}>
							<Gravatar
								email={user?.email}
								size={32}
								default='mp'
								className='w-[20px] lg:w-[32px] rounded-full mr-2'
							/>

							<span className='capitalize hidden md:block'>{displayName}</span>
						</button>
					)}

					<>
						{user && (
							<>
								{transitions((styles, item) =>
									item ? (
										<UserDropDownMenu
											styles={styles}
											user={user}
											logout={logout}
										/>
									) : (
										""
									)
								)}
							</>
						)}
					</>
				</div>
			</div>
		</header>
	);
};

export default Header;
