import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { useGlobalContext } from "../contexts/GlobalContext";

const PaginationItems = (data, searchParams, location) => {
	let items = [];
	for (let i = 1; i <= data?.meta.last_page; i++) {
		items.push(
			<SingleItem
				pageNo={i}
				searchParams={searchParams}
				location={location}
				key={i}
				totalPages={data?.meta?.last_page}
			/>
		);
	}

	return items;
};

const SingleItem = ({ pageNo, totalPages, searchParams, location }) => {
	const curPageNo = searchParams.get("page")
		? parseInt(searchParams.get("page"))
		: 1;

	if (pageNo > 3 && pageNo < totalPages - 3)
		return (
			<span className='relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700'>
				...
			</span>
		);

	return (
		<Link
			to={location.pathname + "?page=" + pageNo}
			aria-current='page'
			className={
				" relative inline-flex items-center border px-4 py-2 text-sm font-medium focus:z-20 " +
				(curPageNo === pageNo
					? "z-10  border-indigo-500 bg-indigo-50  text-indigo-600 "
					: "border-gray-300 bg-white text-gray-500 hover:bg-gray-50 ")
			}>
			{pageNo}
		</Link>
	);
};

const Pagination = () => {
	const { paginationData: data } = useGlobalContext();
	const [searchParams] = useSearchParams();
	const location = useLocation();

	const totalPages = data?.meta?.last_page;

	const curPageNo =
		searchParams.get("page") && parseInt(searchParams.get("page")) > 1
			? parseInt(searchParams.get("page"))
			: 1;

	if (!data || data?.meta?.total < 1) return <></>;

	return (
		<div className='flex items-center justify-between border-t border-gray-200 py-3'>
			{totalPages > 1 && (
				<div className='flex flex-1 justify-between sm:hidden'>
					{curPageNo > 1 && (
						<>
							<Link
								to={
									curPageNo <= 1
										? location.pathname + "?page=" + curPageNo
										: location.pathname + "?page=" + (curPageNo - 1)
								}
								className='relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50'>
								Previous
							</Link>

							<div></div>
						</>
					)}

					{curPageNo < totalPages && (
						<>
							<div></div>
							<Link
								to={
									curPageNo >= totalPages
										? location.pathname + "?page=" + curPageNo
										: location.pathname + "?page=" + (curPageNo + 1)
								}
								className='relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50'>
								Next
							</Link>
						</>
					)}
				</div>
			)}
			<div className='hidden sm:flex sm:flex-1 sm:items-center sm:justify-between'>
				<div>
					<p className='text-sm text-gray-700'>
						Showing <span className='font-medium'>{data?.meta?.from}</span> to{" "}
						<span className='font-medium'>{data?.meta?.to}</span> of{" "}
						<span className='font-medium'>{data?.meta?.total}</span> results
					</p>
				</div>

				{totalPages > 1 && (
					<div>
						<nav
							className='isolate inline-flex -space-x-px rounded-md shadow-sm'
							aria-label='Pagination'>
							<Link
								to={
									curPageNo <= 1
										? location.pathname + "?page=" + curPageNo
										: location.pathname + "?page=" + (curPageNo - 1)
								}
								className='relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20'>
								<span className='sr-only'>Prev</span>
								<FaChevronLeft className='h-5 w-5' aria-hidden='true' />
							</Link>

							{PaginationItems(data, searchParams, location)}

							<Link
								to={
									curPageNo >= totalPages
										? location.pathname + "?page=" + curPageNo
										: location.pathname + "?page=" + (curPageNo + 1)
								}
								className='relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20'>
								<span className='sr-only'>Next</span>
								<FaChevronRight className='h-5 w-5' aria-hidden='true' />
							</Link>
						</nav>
					</div>
				)}
			</div>
		</div>
	);
};

export default Pagination;
