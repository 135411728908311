import { Link } from "react-router-dom";

const NotFound = () => {
	return (
		<div className='bg-gray-100 flex items-center h-[100vh] justify-center'>
			<div className='text-center'>
				<h1 className='font-bold text-[60px] text-center mb-8'>
					404! Page not found!
				</h1>

				<Link
					to='/'
					className='bg-green-600 border-green-700 rounded p-3 px-7 text-white'>
					Back to Home
				</Link>
			</div>
		</div>
	);
};

export default NotFound;
