import "animate.css";
import { Routes, Route } from "react-router-dom";
import PrivateRoutes from "./components/PrivateRoutes";

import Login from "./pages/Login";
import Records from "./pages/Records";
import Settings from "./pages/Settings";
import Purchases from "./pages/Purchases";
import ForgotPassword from "./pages/ForgotPassword";
import PasswordReset from "./pages/PasswordReset";
import NotFound from "./pages/NotFound";
import Users from "./pages/Users";
import Jobsheet from "./pages/print/Jobsheet";
import Status from "./pages/Status";
import SmsTemplates from "./pages/SmsTemplates";

function App() {
	return (
		<>
			<Routes>
				{/* protected routes */}
				<Route element={<PrivateRoutes />}>
					<Route
						path='/print-jobsheet/:recordId'
						exact
						element={<Jobsheet />}
					/>

					<Route path='/records' exact element={<Records />} />
					<Route path='/status' element={<Status />} />
					<Route path='/sms-templates' element={<SmsTemplates />} />
					<Route path='/purchases' element={<Purchases />} />
					<Route path='/users' element={<Users />} />

					<Route path='/settings' element={<Settings />} />
				</Route>

				<Route path='/' exact element={<Login />} />
				<Route path='/forgot-password' exact element={<ForgotPassword />} />
				<Route path='/password-reset/*' element={<PasswordReset />} />
				<Route path='*' element={<NotFound />} />
			</Routes>
		</>
	);
}

export default App;
