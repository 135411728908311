import { useEffect, useRef, useState } from "react";
import RightModal from "../components/RightModal";
import FormGroup from "../components/FormGroup";
import { useGlobalContext } from "../contexts/GlobalContext";

import { apiPatch, apiPost } from "../inc/ApiHelpers";
import { useAuth } from "../hooks/auth";
import { getPostsPerPage } from "../inc/Helpers";

function NewSmsTemplateForm({
	isVisible,
	setData,
	resetForm,
	defaultFormData,
}) {
	const modalRef = useRef(null);

	const { user } = useAuth({ middleware: "auth" });

	const {
		setIsPageLoading,
		updateAnimationState,
		paginationData,
		setShowNewFormModal,
	} = useGlobalContext();

	const [errors, setErrors] = useState();
	const [formData, setFormData] = useState(defaultFormData);

	// update form data on state change.
	useEffect(() => {
		setFormData({
			...defaultFormData,
			organization_id: user?.organization?.id,
		});
	}, [defaultFormData]);

	const formFields = [
		{ id: "title", type: "text", required: 1 },
		{ id: "message", type: "textarea" },
		{ id: "id", type: "hidden", required: 1 },
	];

	const updateFormStates = (key, val) => {
		setFormData(() => ({ ...formData, [key]: val }));
	};

	// create new or update old records.
	const handleFormSubmit = async (e) => {
		e.preventDefault();
		setErrors("");

		const limitTotalPosts = getPostsPerPage(paginationData);

		setIsPageLoading(true);

		// api request.
		try {
			let newData;
			if (formData?.id) {
				//  edit.

				newData = await apiPatch({
					url: "/api/sms-templates/" + formData.id,
					...formData,
				});

				updateAnimationState({
					id: formData.id,
					action: "edit",
					callback: () => {
						// update data
						setData((prev) =>
							prev.map((item) => (item.id === formData.id ? newData : item))
						);
					},
				});
			} else {
				// new post.
				newData = await apiPost({
					url: "/api/sms-templates",
					...formData,
				});

				updateAnimationState({
					id: newData.id,
					action: "edit",
					callback: () => {
						// update data
						setData((prev) => [newData, ...prev].splice(0, limitTotalPosts));
					},
				});
			}
			setShowNewFormModal(false);
		} catch (e) {
			setErrors(e.response.data.errors);
		}

		setIsPageLoading(false);
	};

	return (
		<>
			<RightModal
				errors={errors}
				setErrors={setErrors}
				handleSubmit={handleFormSubmit}
				ref={modalRef}
				isVisible={isVisible}
				isEdit={defaultFormData?.id ? true : false}
				resetFormData={resetForm}>
				{formFields.map((item) => (
					<FormGroup
						key={item.id}
						onChange={updateFormStates}
						item={item}
						errors={errors}
						value={formData[item.id]}
					/>
				))}
			</RightModal>
		</>
	);
}

export default NewSmsTemplateForm;
