import { useEffect, useRef, useState } from "react";
import RightModal from "../components/RightModal";
import FormGroup from "../components/FormGroup";
import { useGlobalContext } from "../contexts/GlobalContext";
import { apiGet } from "../inc/ApiHelpers";

function NewSmsForm({
	record,
	isVisible,
	resetForm,
	organizationSettings,
	setShowSmsModal,
	reset,
	setReset,
}) {
	const modalRef = useRef(null);

	const defaultFormData = {
		template: "",
		title: "",
		message: "",
	};

	const { setIsPageLoading, setToast } = useGlobalContext();

	const [errors, setErrors] = useState();
	const [formData, setFormData] = useState(defaultFormData);
	const [smsTemplates, setSmsTemplates] = useState([]);

	// save form's input field's values into state
	const updateFormStates = (key, val) => {
		setFormData(() => ({ ...formData, [key]: val }));

		if (key === "template") {
			smsTemplates.forEach((item) => {
				if (item.id === val) {
					setFormData((prev) => ({
						...prev,
						title: item.title,
						message: item.message,
					}));
				}
			});
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setErrors("");

		setIsPageLoading(true);

		try {
			const api_url =
				"https://samayasms.com.np/smsapi/index.php?key=" +
				organizationSettings?.samayaSmsApiKey +
				"&campaign=" +
				organizationSettings?.samayaCampaignId +
				"&routeid=" +
				organizationSettings?.samayaRouteId +
				"&type=text&contacts=" +
				record?.phone +
				"&senderid=" +
				organizationSettings?.samayaSenderId +
				"&msg=" +
				encodeURIComponent(formData?.message);

			apiGet("/api/send-sms?url=" + api_url).then((res) => {
				if (res.indexOf("ERR:") === 0) {
					// error
					setToast(res, "danger");
				} else {
					setToast("SMS Sent", "success");
					setShowSmsModal(false);
				}
			});
		} catch (e) {
			setErrors(e.response.data.errors);
		}

		setIsPageLoading(false);
	};

	useEffect(() => {
		// get all sms templates.
		apiGet("/api/sms-templates").then((res) => {
			setSmsTemplates(res.data);
		});
	}, []);

	useEffect(() => {
		if (reset) {
			setFormData(defaultFormData);

			setReset(false);
		}
	}, [reset]);

	return (
		<>
			<RightModal
				setErrors={setErrors}
				ref={modalRef}
				isVisible={isVisible}
				modalHeader='Send SMS'
				submitBtnLabel='Send'
				showResetBtn={false}
				handleSubmit={handleSubmit}
				setShowHideNewFormModal={setShowSmsModal}
				resetFormData={resetForm}>
				{[
					{
						id: "template",
						type: "select",
						required: 1,
						route: "status",
						options: smsTemplates
							? smsTemplates.map((item) => {
									return { label: item.title, value: item.id };
							  })
							: [],
					},
					{
						id: "title",
						type: "text",
						required: 1,
					},
					{
						id: "message",
						type: "textarea",
						required: 1,
					},
				].map((item) => (
					<FormGroup
						key={item.id}
						onChange={updateFormStates}
						item={item}
						errors={errors}
						value={formData[item.id]}
					/>
				))}
			</RightModal>
		</>
	);
}

export default NewSmsForm;
