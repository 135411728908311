const GreenButton = ({ onClick, children }) => {
	return (
		<button
			onClick={onClick}
			className='bg-green-600 hover:bg-green-700 text-white p-3 md:p-2 px-3 rounded text-sm inline-block print:hidden'>
			<div className='flex items-center w-fit'>{children}</div>
		</button>
	);
};

export default GreenButton;
