const Footer = () => {
	return (
		<div className='sticky top-[100vh] px-8 py-6 text-sm print:hidden'>
			&copy; {new Date().getFullYear()}. Hello Mobile Care. All Rights Reserved
			{/* &copy; {new Date().getFullYear()}. Sanil.com.np. All Rights Reserved */}
		</div>
	);
};

export default Footer;
