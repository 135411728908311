import { useEffect, useRef, useState } from "react";

const Autocomplete = ({
	suggestions = [],
	onChange = null,
	className,
	value,
	setValue,
	...props
}) => {
	const [activeSuggestion, setActiveSuggestion] = useState(0);
	const [filteredSuggestions, setFilteredSuggestions] = useState();
	const suggestionContainerRef = useRef(null);

	const filterSuggestions = (query) => {
		if (!query) {
			return suggestions;
		} else {
			return suggestions.filter(
				(suggestion) =>
					suggestion.toLowerCase().indexOf(query.toLowerCase()) > -1
			);
		}
	};

	const onChangeHandler = async (e) => {
		const query = e.target.value;
		setValue(props?.id, query);

		let filteredData;

		if (onChange) {
			filteredData = await onChange(query);
		} else {
			filteredData = filterSuggestions(query);
		}

		setFilteredSuggestions(filteredData);
	};

	const onKeyDown = (e) => {
		if (e.keyCode === 9 || e.keyCode === 27) {
			// tab = 9
			// esc = 27
			setActiveSuggestion(0);
			setFilteredSuggestions("");
		} else if (e.keyCode === 13) {
			e.preventDefault();
			// enter key
			setActiveSuggestion(0);
			setFilteredSuggestions("");
			setValue(props?.id, filteredSuggestions[activeSuggestion]);
		} else if (e.keyCode === 40) {
			// down key

			if (activeSuggestion === filteredSuggestions.length - 1) {
				return;
			}
			setActiveSuggestion(activeSuggestion + 1);
		} else if (e.keyCode === 38) {
			// up key

			if (activeSuggestion === 0) {
				return;
			}

			setActiveSuggestion(activeSuggestion - 1);
		}
	};

	const onClick = (index) => {
		setActiveSuggestion(index);
		setFilteredSuggestions("");
		setValue(props?.id, filteredSuggestions[index]);
	};

	useEffect(() => {
		// hide suggestion div when clicked outside.
		function handleClickOutside(event) {
			if (
				suggestionContainerRef.current &&
				!suggestionContainerRef.current.contains(event.target)
			) {
				setFilteredSuggestions("");
			}
		}

		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [suggestionContainerRef]);

	return (
		<div className='relative'>
			<input
				type='text'
				className={className}
				onChange={onChangeHandler}
				onKeyDown={onKeyDown}
				value={value}
			/>

			<div ref={suggestionContainerRef}>
				{filteredSuggestions && filteredSuggestions.length > 0 && (
					<ul className='absolute border border-blue-300 border-b-[3px] border-t-0 w-full bg-white mt-0 z-[99999] overflow-y-auto'>
						{filteredSuggestions.map((suggestion, index) => (
							<li
								key={index}
								className={
									"p-2 border-b last:border-0 cursor-pointer hover:bg-blue-500 hover:text-white " +
									(index === activeSuggestion ? " bg-blue-500 text-white" : "")
								}
								onClick={() => onClick(index)}>
								{suggestion}
							</li>
						))}
					</ul>
				)}
			</div>
		</div>
	);
};

export default Autocomplete;
