import { useState } from "react";
import FormGroup from "../../components/FormGroup";
import { useAuth } from "../../hooks/auth";
import { apiGet } from "../../inc/ApiHelpers";

const Organization = ({
	selTab,
	setOrganizationFormData,
	organizationFormData,
	errors,
}) => {
	const { user } = useAuth({ middleware: "auth" });
	const [logoRemoved, setLogoRemoved] = useState(false);

	return (
		<>
			{selTab === "organization" && (
				<>
					<h5 className='text-gray-600 font-light text-[30px] mb-6'>
						Organization
					</h5>

					<div className='max-w-[600px]'>
						{[
							{ id: "name", type: "text", required: 1 },
							{ id: "phone", type: "text" },
						].map((item) => (
							<FormGroup
								key={"organization" + item.id}
								onChange={(key, val) =>
									setOrganizationFormData((prev) => ({
										...prev,
										[key]: val,
									}))
								}
								item={item}
								errors={errors}
								value={organizationFormData[item.id]}
							/>
						))}

						{[{ id: "logo", type: "file" }].map((item) => (
							<FormGroup
								key={"organization" + item.id}
								onChange={(key, val) =>
									setOrganizationFormData((prev) => ({
										...prev,
										[key]: val,
									}))
								}
								item={item}
								errors={errors}
								value={organizationFormData[item.id]}
							/>
						))}

						{/* remove logo button */}

						{user?.organization?.logo && !logoRemoved && (
							<button
								type='button'
								className=' rounded ml-[173px] text-xs font-bold text-white  mb-6 border border-red-800 px-4 py-1 bg-red-700'
								onClick={() =>
									apiGet("/api/remove-organization-logo").then((res) => {
										setLogoRemoved(true);
										window.location.reload();
									})
								}>
								Remove Logo
							</button>
						)}

						{[
							{ id: "address", type: "textarea" },
							{ id: "country", type: "text" },
						].map((item) => (
							<FormGroup
								key={"organization" + item.id}
								onChange={(key, val) =>
									setOrganizationFormData((prev) => ({
										...prev,
										[key]: val,
									}))
								}
								item={item}
								errors={errors}
								value={organizationFormData[item.id]}
							/>
						))}
					</div>
				</>
			)}
		</>
	);
};

export default Organization;
