import React, { useEffect } from "react";
import { FaRegTimesCircle } from "react-icons/fa";
import { animated, useTransition } from "@react-spring/web";
import AnimatedButton from "./AnimatedButton";
import { useGlobalContext } from "../contexts/GlobalContext";

const RightModal = React.forwardRef(
	(
		{
			children,
			handleSubmit,
			isVisible,
			resetFormData,
			isEdit,
			setErrors,
			modalHeader,
			submitBtnLabel = "Submit",
			showResetBtn = true,
			setShowHideNewFormModal,
		},
		ref
	) => {
		const { setShowNewFormModal, pageTitle } = useGlobalContext();

		const transitions = useTransition(isVisible, {
			from: { opacity: 0, x: 24 },
			enter: { opacity: 1, x: 0 },
			leave: { opacity: 0, x: 24 },
		});

		const hideModalForm = () => {
			if (setShowHideNewFormModal) {
				setShowHideNewFormModal(false);
			} else {
				setShowNewFormModal(false);
			}
			setErrors("");
		};

		useEffect(() => {
			// hide modal form when clicked outside.
			function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target)) {
					if (setShowHideNewFormModal) {
						setShowHideNewFormModal(false);
					} else {
						setShowNewFormModal(false);
					}
					setErrors("");
				}
			}

			// Bind the event listener
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				// Unbind the event listener on clean up
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}, [setShowNewFormModal, setShowHideNewFormModal, ref]);

		return (
			<>
				{transitions((styles, item) =>
					item ? (
						<>
							<animated.div
								style={styles}
								className='right-modal fixed top-0 right-0 bg-white w-full md:w-[600px] overflow-x-hidden overflow-y-auto h-[100vh] text-xs z-[99999]'
								ref={ref}>
								{/* page title */}
								<div className='flex justify-between items-center bg-indigo-100 p-6'>
									<div>
										{modalHeader ? (
											<>
												<h2 className='text-[20px]  font-semibold flex-1 leading-0 capitalize mb-2'>
													{modalHeader}
												</h2>
												<p className='text-xs mt-0  text-opacity-[0.8]'>
													All fields with{" "}
													<span className='text-red-500 font-bold'>*</span> sign
													are required.
												</p>
											</>
										) : (
											<>
												<h2 className='text-[20px]  font-semibold flex-1 leading-0 capitalize mb-2'>
													{isEdit ? "Edit " : "Add New "} {pageTitle}
												</h2>
												<p className='text-xs mt-0  text-opacity-[0.8]'>
													All fields with{" "}
													<span className='text-red-500 font-bold'>*</span> sign
													are required.
												</p>
											</>
										)}
									</div>

									<button className='text-[24px] ' onClick={hideModalForm}>
										<FaRegTimesCircle />
									</button>
								</div>

								{/* main form */}
								<form
									onSubmit={handleSubmit}
									method='POST'
									className='w-full p-6'>
									<div className=''>
										{children}

										<div className='border-t mt-4 pt-4 flex items-center'>
											<AnimatedButton
												type='submit'
												label={submitBtnLabel}
												cssClass='bg-green-500 border-green-500 text-white w-[200px]'
											/>
											{showResetBtn && (
												<button
													type='button'
													onClick={resetFormData}
													className='bg-gray-600 text-white hover:bg-gray-700 px-4 py-2 border border-gray-700 hover:border-gray-700 ml-3 rounded w-[100px]'>
													Reset
												</button>
											)}

											<button
												type='button'
												className='bg-gray-300 hover:bg-gray-400 px-4 py-2 border border-gray-300 hover:border-gray-400 ml-3 rounded w-[100px]'
												onClick={hideModalForm}>
												{" "}
												Close
											</button>
										</div>
									</div>
								</form>
							</animated.div>
						</>
					) : (
						""
					)
				)}
				{isVisible && (
					<div className='bg-black bg-opacity-20 w-full fixed h-full top-0 left-0 z-[9999] '></div>
				)}
			</>
		);
	}
);

export default RightModal;
