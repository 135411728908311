import Table from "../components/Table";
import { useEffect, useState } from "react";
import PageHeader from "../components/PageHeader";

import AppLayout from "../layouts/AppLayout";
import NewUserForm from "./NewUserForm";
import { apiGet, apiPatch, deletePost } from "../inc/ApiHelpers";
import Pagination from "../components/Pagination";
import { useSearchParams } from "react-router-dom";
import { useGlobalContext } from "../contexts/GlobalContext";
import { getData } from "../inc/Helpers";
import { useAuth } from "../hooks/auth";

function Users() {
	const arraySort = require("array-sort");

	const {
		setPaginationData,
		showNewFormModal,
		setShowNewFormModal,
		updatePageTitle,
		setToast,
		updateAnimationState,
		hasPermission,
		sortTableBy,
	} = useGlobalContext();

	const { user } = useAuth({ middleware: "auth" });

	const pathName = "/users";

	const [data, setData] = useState();

	const [isLoading, setIsLoading] = useState(false);
	const [searchParams] = useSearchParams();
	const [roles, setRoles] = useState([]);
	const [formFields, setFormFields] = useState([
		{ id: "name", type: "text", required: 1 },
		{ id: "email", type: "email", required: 1 },
		{
			id: "password",
			type: "password",
			required: 1,
		},
		{
			id: "password_confirmation",
			type: "password",
			required: 1,
		},

		{
			id: "perm",
			name: "User Role",
			type: "legend",
		},

		{
			id: "user_role",
			name: "Select Role",
			type: "select",
			required: 1,
		},

		{ id: "id", type: "hidden" },
	]);

	const curPageNo = searchParams.get("page")
		? parseInt(searchParams.get("page"))
		: 1;

	const emptyFormData = {
		name: "",
		email: "",
		created_at: "",
		password: "",
		password_confirmation: "",
		seloption: { label: "Administrator", value: 1 },
		user_role: 1,
		id: "",
	};

	const [showVisibleColumnsDropdown, setShowVisibleColumnsDropdown] =
		useState(false);
	const [visibleTableColumns, setVisibleTableColumns] = useState({
		name: true,
		email: true,
		created_at: true,
		role: true,
		id: true,
		action: true,
	});

	const [defaultFormData, setDefaultFormData] = useState(emptyFormData);

	/**
	 * It resets the form data to default values and shows the modal.
	 */
	const showEmptyFormModal = () => {
		// reset form data when clicked on green "add new " btn.
		setDefaultFormData(emptyFormData);

		// show modal.
		setShowNewFormModal(true);
	};

	/**
	 * Open modal form and auto fill all the input fields.
	 * @param id - the id of the user that is being edited
	 */
	const onEditClick = (id) => {
		let selData = data.filter((item) => item.id === id);

		// set selected options for user role.
		let selOptions = [];
		roles.forEach((item) => {
			if (item.id == selData[0].role_id) {
				selOptions.push({ label: item.name, value: item.id });
			}
		});
		selData[0].seloption = selOptions;
		selData[0]["user_role"] = selData[0].role_id;

		// update default form data.
		setDefaultFormData(...selData);

		// mark password as not required, on edit.
		setFormFields((prev) =>
			prev.map((item) =>
				item.id === "password" || item.id === "password_confirmation"
					? { ...item, required: 0 }
					: item
			)
		);

		// show modal.
		setShowNewFormModal(true);
	};

	/**
	 * It deletes a row from the database and updates the state
	 * @param id - the id of the row to delete.
	 */
	const deleteRow = async (id) => {
		try {
			deletePost("/api/users/" + id);

			updateAnimationState({
				id: id,
				action: "delete",
				callback: () => {
					// update state.
					setData((prev) => prev.filter((item) => item.id !== id));
				},
			});
		} catch (e) {
			setToast(e.response.data.message, "danger");
		}
	};

	/**
	 * `resetForm` resets the form fields to their default values
	 */
	const resetForm = () => {
		setDefaultFormData(emptyFormData);
	};

	/**
	 * When the user changes the value of a checkbox, update the state of the visibleTableColumns object
	 * with the new value.
	 * @param e - the event object
	 */
	const handleVisibleColumnOptionChange = (e) => {
		setVisibleTableColumns((prev) => ({
			...prev,
			[e.target.id]: e.target.checked,
		}));

		// update in api
		apiPatch({
			url: "/api/tablesettings/update-visible-columns/users",
			...{
				visibleColumns: {
					...visibleTableColumns,
					[e.target.id]: e.target.checked,
				},
			},
		});
	};

	useEffect(() => {
		// get all user roles.
		apiGet("/api/roles").then((roles) => {
			setRoles(roles);

			// update formFields
			setFormFields((prev) =>
				prev.map((item) =>
					item.id === "user_role"
						? {
								...item,
								options:
									roles && roles.length > 0
										? roles.map((i) => ({
												value: i.id,
												label: i.name,
										  }))
										: [],
						  }
						: item
				)
			);
		});
	}, []);

	useEffect(() => {
		/* A callback function that fetches data from the server and updates the state. */
		getData(
			"/api/users?page=" + curPageNo,
			setIsLoading,
			apiGet,
			setData,
			setPaginationData,
			setToast
		);

		/* It updates the page title. */
		updatePageTitle(pathName);
	}, [curPageNo]);

	useEffect(() => {
		setData((prev) =>
			arraySort(prev, sortTableBy[0], {
				reverse: sortTableBy[1] === "asc",
			})
		);
	}, [sortTableBy]);

	useEffect(() => {
		apiGet("/api/tablesettings/get-visible-columns/users").then((res) => {
			// update visibleTableColumns state.
			setVisibleTableColumns((prev) => ({ ...prev, ...res.visibleColumns }));
		});
	}, []);

	if (!hasPermission(["administrator"], user)) {
		return (
			<div className='text-center flex justify-center items-center h-[100vh] font-bold text-lg'>
				Access Denied !
			</div>
		);
	}

	return (
		<AppLayout>
			<div className='app-body px-3 md:px-8 pt-5 md:pt-6  print:p-0'>
				<PageHeader onClick={showEmptyFormModal} />

				<Table
					theads={[
						{ id: "id", label: "ID", sortBy: "id" },
						"name",
						"email",
						{
							id: "role",
							label: "Role",
							sortBy: "role.name",
						},
						"action",
					]}
					setShowModal={showEmptyFormModal}
					data={data}
					onEditClick={onEditClick}
					handleDelete={deleteRow}
					isLoading={isLoading}
					isSearch={false}
					showVisibleColumnsDropdown={showVisibleColumnsDropdown}
					setShowVisibleColumnsDropdown={setShowVisibleColumnsDropdown}
					handleVisibleColumnOptionChange={handleVisibleColumnOptionChange}
					visibleTableColumns={visibleTableColumns}
				/>

				<Pagination />
			</div>
			<NewUserForm
				setData={setData}
				defaultFormData={defaultFormData}
				isVisible={showNewFormModal}
				resetForm={resetForm}
				roles={roles}
				formFields={formFields}
			/>
		</AppLayout>
	);
}

export default Users;
