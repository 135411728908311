import { animated } from "@react-spring/web";
import { Link } from "react-router-dom";

const UserDropDownMenu = ({ styles, user, logout }) => {
	return (
		<animated.div
			style={styles}
			className='absolute bg-white top-[45px] border border-t-0 right-0 md:right-[-16px] shadow-md rounded-b'>
			<div className=' py-3 px-4'>
				<span className='font-semibold capitalize'>{user?.name}</span>
				<span className='text-xs block text-gray-400 mb-2'>{user?.email}</span>
				<span className='text-xs block text-gray-600 font-semibold'>
					{user?.organization?.name}
				</span>
			</div>
			<ul className='min-w-[100px]'>
				<li className=' border-t-[1px] '>
					<Link to='/settings' className='py-2 px-4 block hover:bg-blue-50'>
						Settings
					</Link>
				</li>
				<li className=' border-t-[1px] '>
					<button
						onClick={logout}
						type='button'
						className='py-2 px-4 w-[100%] text-left hover:bg-blue-50 '>
						Logout
					</button>
				</li>
			</ul>
		</animated.div>
	);
};

export default UserDropDownMenu;
