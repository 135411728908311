import { useState } from "react";
import Select from "react-select";

const PurchaseFilter = ({ handleFilterSearch, setIsSearch, isSearch }) => {
	const defaultFilterData = {
		status: { value: "unpaid", label: "Not Paid" },
	};

	const [filterData, setFilterData] = useState(defaultFilterData);

	const resetFilterSearch = () => {
		// turn off search mode.
		setIsSearch(false);

		// reset input fields.
		setFilterData(defaultFilterData);

		//  trigger form submit.
		handleFilterSearch({
			...defaultFilterData,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		// turn on search mode.
		setIsSearch(true);

		handleFilterSearch(filterData);
	};

	/**
	 * When the user changes the value of a filter, update the filterData object with the new value.
	 * @param key - The key of the filterData object that you want to update.
	 * @param val - The value of the input field
	 */
	const handleOnChange = (key, val) => {
		setFilterData((prevState) => ({ ...prevState, [key]: val }));
	};

	console.log("filterData", filterData);

	return (
		<form
			onSubmit={handleSubmit}
			method='POST'
			className='filter border bg-white p-2 text-xs flex justify-content-between items-center print:hidden'>
			<div className='flex-1 flex flex-col md:flex-row'>
				<div className='flex'>
					<div className='lg:mr-2 flex items-center'>
						<label htmlFor='status' className='font-semibold mr-4'>
							Status
						</label>

						<Select
							styles={{
								control: (provided) => ({
									...provided,
									borderRadius: "0px",
									borderColor: "#d8d8d8",
								}),
							}}
							options={[
								{ id: "all", title: "All" },
								{ id: "paid", title: "Paid" },
								{ id: "unpaid", title: "Not Paid" },
							].map((item) => ({
								label: item.title,
								value: item.id,
							}))}
							value={filterData.status}
							onChange={(selOption) => {
								handleOnChange("status", selOption);
							}}
						/>
					</div>
					<button
						type='submit'
						className=' bg-indigo-200 p-2 px-3 rounded-sm border border-indigo-300 font-semibold md:mr-6'>
						Submit
					</button>

					{isSearch && (
						// for .md only
						<button
							type='button'
							title='Reset Search searchQuery'
							onClick={resetFilterSearch}
							className='hidden md:ml-[-20px] md:block lg:hidden ml-1 mr-6 bg-gray-200 hover:bg-gray-400 p-2 px-3 rounded-sm border border-gray-300 hover:border-gray-400 hover:text-white font-semibold '>
							Reset
						</button>
					)}

					{isSearch && (
						// for mobile devices
						<button
							type='button'
							title='Reset Search searchQuery'
							onClick={resetFilterSearch}
							className='md:hidden ml-2 bg-gray-200 hover:bg-gray-400 p-2 px-3 rounded-sm border border-gray-300 hover:border-gray-400 hover:text-white font-semibold '>
							Reset
						</button>
					)}
				</div>
			</div>

			{isSearch && (
				// for larger device
				<button
					type='button'
					title='Reset Search searchQuery'
					onClick={resetFilterSearch}
					className='hidden lg:block ml-2 bg-gray-200 hover:bg-gray-400 p-2 px-3 rounded-sm border border-gray-300 hover:border-gray-400 hover:text-white font-semibold '>
					Reset
				</button>
			)}
		</form>
	);
};

export default PurchaseFilter;
