import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Footer from "../components/Footer";
import Header from "../components/Header";

const AppLayout = ({ children }) => {
	return (
		<>
			<Header />
			<div className=' bg-gray-50 min-h-[100vh]'>
				{children}

				<ToastContainer />

				<Footer />
			</div>
		</>
	);
};

export default AppLayout;
