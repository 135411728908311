import {
	FaPencilAlt,
	FaTrash,
	FaPrint,
	FaTrashRestoreAlt,
	FaSms,
} from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { useGlobalContext } from "../../contexts/GlobalContext";
import { useAuth } from "../../hooks/auth";

const Td = ({
	fieldName,
	colIndex,
	rowIndex,
	allData,
	handleDelete,
	onEditClick,
	animationState,
	handleRestore,

	...props
}) => {
	const { user } = useAuth({ middleware: "auth" });
	const { hasPermission } = useGlobalContext();
	const location = useLocation();

	let tdClass = "p-2 px-2 md:p-3 py-1 md:py-2 border-b print:p-1 ";

	if (animationState?.id === allData["id"]) {
		if (animationState?.action === "delete") {
			tdClass += " transition-transform scale-0 ";
		} else {
			tdClass +=
				" transition-transform bg-green-200 animate__animated  animate__flash";
		}
	}

	if (fieldName === "#") {
		return (
			<td key={fieldName + "-" + colIndex} className={tdClass}>
				{rowIndex + 1}
			</td>
		);
	} else if (fieldName === "created_at") {
		return (
			<td key={fieldName + "-" + colIndex} className={tdClass}>
				{new Date(allData[fieldName]).toISOString().substring(0, 10)}
			</td>
		);
	} else if (fieldName === "action") {
		// -------------------
		// ACTION BTNS
		// -------------------
		return (
			<td key={fieldName + "-" + colIndex} className={tdClass} colSpan='2'>
				<div className='flex print:hidden'>
					{hasPermission(["administrator", "manager"], user) &&
						!allData?.deleted_at && (
							<button
								title='Edit'
								onClick={() => {
									onEditClick(allData["id"]);
								}}
								className='border p-2 mr-2 rounded hover:bg-blue-600 hover:text-white hover:border-blue-600'>
								<FaPencilAlt />
							</button>
						)}

					{handleDelete &&
						hasPermission(["administrator"], user) &&
						!allData?.deleted_at && (
							<button
								title='Delete'
								onClick={() => {
									if (window.confirm("Are you sure?")) {
										handleDelete(allData["id"]);
									}
								}}
								className={
									"border p-2 mr-2 rounded hover:bg-red-600 hover:text-white hover:border-red-600" +
									(location.pathname === "/users" && user?.id === allData.id
										? " hidden "
										: "")
								}>
								<FaTrash />
							</button>
						)}

					{handleRestore &&
						hasPermission(["administrator"], user) &&
						allData?.deleted_at && (
							<button
								title='Restore'
								onClick={() => {
									if (window.confirm("Are you sure?")) {
										handleRestore(allData["id"]);
									}
								}}
								className='border p-2 mr-2 rounded hover:bg-red-600 hover:text-white hover:border-red-600'>
								<FaTrashRestoreAlt />
							</button>
						)}

					{props.handleJobsheetPrint && (
						<button
							title='Print Jobsheet'
							onClick={() => props.handleJobsheetPrint(allData["id"])}
							className='border p-2 mr-2 rounded hover:bg-red-600 hover:text-white hover:border-red-600'>
							<FaPrint />
						</button>
					)}

					{props.handleSmsSend && (
						<button
							title='SMS'
							onClick={() => props.handleSmsSend(allData["id"])}
							className='border p-2 mr-2 rounded hover:bg-green-600 hover:text-white hover:green-red-600'>
							<FaSms />
						</button>
					)}
				</div>
			</td>
		);
	} else if (fieldName === "color") {
		return (
			<td key={fieldName + "-" + colIndex} className={tdClass}>
				<div
					className='w-[40px] md:w-[60px] h-[32px] '
					style={{ background: allData[fieldName] }}></div>
			</td>
		);
	} else if (fieldName === "status") {
		return (
			<td key={fieldName + "-" + colIndex} className={tdClass}>
				<div
					className={
						"text-[10px] inline-block leading-[1.2] text-white px-2 py-1 rounded  font-semibold" +
						(allData?.statuses?.deleted_at ? " line-through " : "")
					}
					style={{
						background: !allData["status_color"]
							? "#000"
							: allData["status_color"],
					}}>
					{allData[fieldName]}
				</div>
			</td>
		);
	} else
		return (
			<td key={fieldName + "-" + colIndex} className={tdClass}>
				{fieldName === "role"
					? allData?.role?.name
					: allData[fieldName]
					? allData[fieldName]
					: "-"}
				{allData?.deleted_at && fieldName === "title" ? (
					<span className='text-red-600 font-bold'>- trashed</span>
				) : (
					""
				)}
			</td>
		);
};

export default Td;
