const defaultMobileModels = () => {
	return [
		"M51",
		"M52",
		"S22 Ultra",
		"Tab Active4 Pro",
		"A23 5G",
		"S20 FE 2022",
		"A53 5G",
		"A73 5G",
		"A33 5G",
		"S22+ 5G",
		"S22 5G",
		"S21 FE 5G",
		"S21 Ultra 5G",
		"S21+ 5G",
		"S21 5G",
		"M31 Prime",
		"iPhone 7",
		"iPhone 8",
		"iPhone 9",
		"iPhone 10",
		"iPhone 11",
		"iPhone 12",
		"iPhone 13",
		"iPhone 14",
	];
};

export default defaultMobileModels;
